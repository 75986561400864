import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ChildInfo from '../components/ChildInfo';
import DialogContent from '@mui/material/DialogContent';
import { Link } from 'react-router-dom';
import { OrnamentItem, useGetChildInfo } from '../services/api';

const ChildInfoModal = ({
  currentChild,
  handleClose,
}: {
  currentChild: OrnamentItem;
  handleClose: () => void;
}) => {
  const { data, isLoading } = useGetChildInfo(currentChild.id, currentChild.itemType);

  return (
    <Dialog
      aria-labelledby='child information popup'
      onClose={handleClose}
      open={true}
      scroll='paper'
    >
      <DialogTitle id='child-information'>
        {currentChild.age} YEAR OLD {currentChild.gender.toLowerCase() === 'male' ? 'BOY' : 'GIRL'}
      </DialogTitle>
      <DialogContent dividers style={{ backgroundColor: '#f5f5f5' }}>
        <ChildInfo childInfo={data} isLoading={isLoading} />
      </DialogContent>
      <DialogActions sx={[{ gap: '.5rem' }]}>
        <Button color='primary' onClick={handleClose}>
          Back
        </Button>
        <Button
          variant='contained'
          color='primary'
          autoFocus
          component={Link}
          to={`/donate/${currentChild.id}/${currentChild.itemType}`}
        >
          Donate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChildInfoModal;
