import React from 'react';
import { createContext, Suspense, useMemo, useReducer, lazy } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import Tree from './pages/Tree';
import { appReducer, initialAppState, initialTreeState, treeReducer } from './services/state';
import Loading from './pages/Loading';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { doNotRetryOn4xx } from './services/api';

type AppContextValues = {
  appState: any;
  appDispatch: any;
};

const Commitment = lazy(() => import('./pages/Donation'));
const Admin = lazy(() => import('./pages/Admin'));
const Login = lazy(() => import('./pages/Login'));
const Success = lazy(() => import('./pages/Success'));
const PickAnother = lazy(() => import('./pages/PickAnother'));
export const TreeContext = createContext({});
export const AppContext = createContext<AppContextValues>({ appState: null, appDispatch: null });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
      retry: doNotRetryOn4xx,
    },
  },
});

function App() {
  // tree state setup
  const [treeState, treeDispatch] = useReducer(treeReducer, initialTreeState);
  const treeContextValue = useMemo(() => ({ treeState, treeDispatch }), [treeState]);

  // app state setup
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);
  const appContextValue = useMemo(() => ({ appState, appDispatch }), [appState]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route element={<AppContextRoute value={appContextValue} />}>
              <Route element={<TreeContextRoute value={treeContextValue} />}>
                <Route path='/' element={<Tree />} />
              </Route>
              <Route path='donate/:childId/:itemType' element={<Commitment />} />
              <Route path='success' element={<Success />} />
              <Route path='pick-another' element={<PickAnother />} />
              <Route path='admin' element={<Admin />} />
              <Route path='login' element={<Login />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

const AppContextRoute = ({ value }) => (
  <AppContext.Provider value={value}>
    <Outlet />
  </AppContext.Provider>
);

const TreeContextRoute = ({ value }) => (
  <TreeContext.Provider value={value}>
    <Outlet />
  </TreeContext.Provider>
);

export default App;
