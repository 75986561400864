import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { INSTRUCTIONS, SITE_BANNER } from '../services/config';

const InstructionModal = ({ isModalOpen, setModalOpen }) => {
  const handleCloseModal = () => setModalOpen(false);
  return (
    <Dialog
      aria-labelledby='instructions popup'
      onClose={handleCloseModal}
      open={isModalOpen}
      scroll='paper'
    >
      <DialogTitle>Welcome to {SITE_BANNER}</DialogTitle>
      <StyledContent dividers>
        <DialogContentText tabIndex={-1}>{INSTRUCTIONS}</DialogContentText>
      </StyledContent>
      <ActionArea>
        <Button variant='text' color='primary' onClick={() => setModalOpen(false)}>
          ok
        </Button>
      </ActionArea>
    </Dialog>
  );
};
const StyledContent = styled(DialogContent)`
  white-space: pre-wrap;
`;

const ActionArea = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;
export default InstructionModal;
